import axios from './Repository';
import config from '../configs/ApplicationConfig';

export default {
    refreshToken({ accessToken, refreshToken, idToken }) {
        return axios.put(
            `${config.authEndpoint}/api/login/refresh-token`,
            null,
            {
                headers: {
                    accessToken,
                    refreshToken,
                    idToken,
                    Accept: 'application/json',
                },
            }
        );
    },
};
