import {
    CANDIDATE_SET,
    CANDIDATE_SET_DATA_EXPORT,
    CANDIDATE_UPDATE,
} from '../../mutation-types';
import { RepositoryFactory } from '../../../repository/RepositoryFactory';
const candidateApi = RepositoryFactory.get('candidate');

const initState = {
    candidates: [],
    total: 0,
    candidateExport: [],
};

const mutations = {
    [CANDIDATE_SET](state, data) {
        state.candidates = data.content;
        state.total = data.totalElements;
    },
    [CANDIDATE_SET_DATA_EXPORT](state, data) {
        state.candidateExport = data.content;
    },
    [CANDIDATE_UPDATE](state, candidate) {
        const { candidates } = state;
        const candidateFilter = candidates.filter(
            (item) => item.id != candidate.id
        );
        state.candidates = [...candidateFilter, candidate];
    },
};

const actions = {
    async fetch({ commit }, params) {
        const {
            data: { data: candidates },
        } = await candidateApi.fetch(params);
        commit(CANDIDATE_SET, candidates);
    },

    async updateCandidate({ commit }, params) {
        const {
            data: { data: candidate },
        } = await candidateApi.editCandidate(params);
        // commit(CANDIDATE_UPDATE, candidate);
    },

    async fetchInSalon({ commit }, params) {
        const {
            data: { data: candidates },
        } = await candidateApi.fetchInSalon(params);
        commit(CANDIDATE_SET, candidates);
    },

    async fetchDataExport({ commit }, params) {
        const {
            data: { data: candidates },
        } = await candidateApi.fetchDataExport(params);
        commit(CANDIDATE_SET_DATA_EXPORT, candidates);
    },

    async importCandidate(_, file) {
        await candidateApi.importCandidate(file);
    },
};

const getters = {};

export default {
    namespaced: true,
    mutations,
    actions,
    state: initState,
    getters,
};
