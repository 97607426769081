export const AUTHENTICATE_SET_CREDENTIALS = 'AUTHENTICATE_SET_CREDENTIALS';
export const AUTHENTICATE_SET_USER = 'AUTHENTICATE_SET_USER';
export const AUTHENTICATE_SET_PERMISSIONS = 'AUTHENTICATE_SET_PERMISSIONS';
export const DATA_SET_SALONS = 'DATA_SET_SALONS';
export const DATA_SET_SALONAREAS = 'DATA_SET_SALONAREAS';
export const DATA_SET_CATEGORIES = 'DATA_SET_CATEGORIES';
export const SOURCE_SET = 'SOURCE_SET';
export const POSITION_SET = 'POSITION_SET';
export const CITY_SET = 'CITY_SET';
export const CANDIDATE_SET = 'CANDIDATE_SET';
export const CANDIDATE_UPDATE = 'CANDIDATE_UPDATE';
export const CANDIDATE_SET_DATA_EXPORT = 'CANDIDATE_SET_DATA_EXPORT';
export const ROUND_SET = 'ROUND_SET';
export const SALON_SET = 'SALON_SET';
export const HISTORY_SET_ID_CANDIDATE = 'HISTORY_SET_ID_CANDIDATE';
