import candidateRepository from './candidateRepository';
import cityRepository from './cityRepository';
import positionRepository from './positionRepository';
import sourceRepository from './sourceRepository';
import roundRepository from './roundRepository';
import salonRepository from './salonRepository';

const repositories = {
    candidate: candidateRepository,
    city: cityRepository,
    staffType: positionRepository,
    source: sourceRepository,
    round: roundRepository,
    salon: salonRepository,
};

export const RepositoryFactory = {
    get: (name) => repositories[name],
};
