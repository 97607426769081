import Vuex from 'vuex';
import Vue from 'vue';
import authenticate from './modules/Authenticate';
import source from './modules/source';
import position from './modules/position';
import city from './modules/city';
import candidate from './modules/candidate';
import round from './modules/round';
import salon from './modules/salon';
import historyInterview from './modules/historyInterview';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        authenticate,
        source,
        position,
        city,
        candidate,
        round,
        salon,
        historyInterview,
    },
});

export default store;
