import Vue from 'vue';
import Router from 'vue-router';
import { match } from 'path-to-regexp';
import App from '../App';
const RecruitmentWebAdmin = () => import('../pages/RecruitmentWebAdmin');
const CandidateForm = () => import('../pages/CreateCandidate');
const CandidateImport = () => import('../pages/ImportCandidate');
const CandidateList = () => import('../pages/ListCandidate');
const Dashboard = () => import('../pages/Dashboard/Dashboard');
const page404 = () => import('../pages/Error/page404');
const page403 = () => import('../pages/Error/403');
const CandidateListOnlineInterview = () =>
    import('../pages/ListCandidateOnlineInterview');
const ListCandidateMapToSalon = () =>
    import('../pages/ListCandidateMapToSalon');

const ListCandidateFail = () => import('../pages/ListCandidateFail');

const ListCandidateInSalon = () => import('../pages/ListCandidateInSalon');

Vue.use(Router);

let router = new Router({
    routes: [
        {
            path: '/',
            component: App,
            children: [
                {
                    path: '',
                    component: RecruitmentWebAdmin,
                    children: [
                        {
                            path: '',
                            name: 'Dashboard',
                            component: Dashboard,
                        },
                        {
                            path: 'dashboard',
                            name: 'Dashboard',
                            component: Dashboard,
                        },
                        {
                            path: '/candidate/form',
                            name: 'Tạo mới hồ sơ ứng viên',
                            component: CandidateForm,
                        },
                        {
                            path: '/candidate/edit/:id',
                            name: 'Cập nhật hồ sơ ứng viên',
                            component: CandidateForm,
                        },
                        {
                            path: '/candidate/import',
                            name: 'Nhập hồ sơ ứng viên',
                            component: CandidateImport,
                        },
                        {
                            path: '/candidate',
                            name: 'Danh sách ứng viên',
                            component: CandidateList,
                        },
                        {
                            path: '/candidate-online',
                            name: 'Danh sách chờ phỏng vấn online',
                            component: CandidateListOnlineInterview,
                        },
                        {
                            path: '/map-candidate-to-salon',
                            name: 'Map ứng viên vào salon',
                            component: ListCandidateMapToSalon,
                        },
                        {
                            path: '/candidate-in-salon',
                            name: 'Ứng viên phỏng vấn tại salon',
                            component: ListCandidateInSalon,
                        },
                        {
                            path: '/candidate-fail',
                            name: 'Danh sách ứng viên không đạt',
                            component: ListCandidateFail,
                        },
                        {
                            path: '/403',
                            component: page403,
                        },
                        {
                            path: '*',
                            name: 'page404',
                            component: page404,
                        },
                    ],
                },
            ],
        },
    ],
    mode: 'history',
    linkActiveClass: 'active', // active class for non-exact links.
    linkExactActiveClass: 'exact-active', // active class for *exact* links.
});

router.beforeEach((to, from, next) => {
    let userPermissionString = localStorage.getItem('userPermissions');
    if (userPermissionString === null || userPermissionString.length === 0) {
        next({ path: '/403' });
    }
    const userPermissions = JSON.parse(userPermissionString);
    if (
        to.matched.some((record) => record.meta.requireAuth) &&
        !userPermissions.some((permission) => {
            let checkPath = match(permission.pageId, {
                decode: decodeURIComponent,
            });
            return checkPath(to.fullPath);
        })
    ) {
        next('/403');
    } else {
        next();
    }
});

export default router;
