import { CITY_SET } from '../../mutation-types';
import { RepositoryFactory } from '../../../repository/RepositoryFactory';
const cityApi = RepositoryFactory.get('city');

const initState = {
    data: [],
};

const mutations = {
    [CITY_SET](state, data) {
        state.data = data;
    },
};

const actions = {
    async fetch({ commit }) {
        const {
            data: { data: city },
        } = await cityApi.fetch();
        commit(CITY_SET, city);
    },
};

const getters = {
    getCities(state) {
        return state.data;
    }
};

export default {
    namespaced: true,
    mutations,
    actions,
    state: initState,
    getters,
};
