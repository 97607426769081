import { SOURCE_SET } from '../../mutation-types';
import { RepositoryFactory } from '../../../repository/RepositoryFactory.js';

const sourceApi = RepositoryFactory.get('source');

const initState = {
    data: [],
};

const mutations = {
    [SOURCE_SET](state, data) {
        state.data = data;
    },
};

const actions = {
    async fetch({ commit }) {
        const {
            data: { data: source },
        } = await sourceApi.fetch();
        commit(SOURCE_SET, source);
    },
};

const getters = {
    getSources(state) {
        return state.data
    }
};

export default {
    namespaced: true,
    mutations,
    actions,
    state: initState,
    getters,
};
