<template>
    <a-locale-provider :locale="locale">
        <a-layout class="App">
            <router-view v-if="isAuthenticated"></router-view>
        </a-layout>
    </a-locale-provider>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import http from 'http';
import https from 'http';
import axios from 'axios';
import axiosRepository from '@/repository/Repository';
import { salonRepository } from '@/repository/salonRepository';
import vi_VN from 'ant-design-vue/lib/locale-provider/vi_VN';
import config from '../src/configs/ApplicationConfig';
import authUtil from './utils/authUtils';

axios.defaults.httpAgent = new http.Agent({ keepAlive: true });
axios.defaults.httpsAgent = new https.Agent({ keepAlive: true });

// Handle refresh token
axiosRepository.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (
            error.response.status === 401 &&
            originalRequest.url.includes(config.baseEndpoint)
        ) {
            const accessToken = localStorage.getItem('AccessToken');
            const refreshToken = localStorage.getItem('RefreshToken');
            const idToken = localStorage.getItem('IdToken');
            let userLogin = {
                accessToken,
                refreshToken,
                idToken,
            };

            const isEmpty = Object.values(userLogin).some(
                (x) => x == null || x == ''
            );

            if (isEmpty) return Promise.reject(error);

            let userLoginRef = await authUtil.refreshToken(userLogin);

            localStorage.setItem('AccessToken', userLoginRef.accessToken);
            axiosRepository.defaults.headers.common['Authorization'] =
                'Bearer ' + userLoginRef.accessToken;

            originalRequest.headers = {
                Authorization: 'Bearer ' + userLoginRef.accessToken,
            };
            return axiosRepository(originalRequest);
        } else if (
            error.response.status === 401 &&
            originalRequest.url.includes(config.authEndpoint)
        ) {
            // eslint-disable-next-line no-undef
            await ssoLogin();
        }

        return Promise.reject(error);
    }
);

export default {
    name: 'app',
    data() {
        return {
            isAuthenticated: false,
            locale: vi_VN,
        };
    },
    methods: {
        ...mapActions('authenticate', [
            'setUser',
            'setCredentials',
            'setUserPermissions',
        ]),
        ...mapActions('staticData', ['setSalons', 'setSalonAreas']),
        ...mapActions('source', { fetchSource: 'fetch' }),
        ...mapActions('position', { fetchPosition: 'fetch' }),
        ...mapActions('city', { fetchCity: 'fetch' }),
        ...mapActions('round', { fetchRound: 'fetch' }),
        ...mapActions('salon', { fetchSalon: 'fetch' }),
    },

    computed: {
        ...mapGetters('staticData', ['getSalons']),
    },

    async created() {
        try {
            // eslint-disable-next-line no-undef
            const creds = await ssoLogin();
            axios.defaults.headers.common['Authorization'] =
                'Bearer ' + creds.AccessToken;
            axiosRepository.defaults.headers.common['Authorization'] =
                'Bearer ' + creds.AccessToken;
            salonRepository.defaults.headers.common['Authorization'] =
                'Bearer ' + creds.AccessToken;

            this.setCredentials({ credentials: creds });
            await this.setUser();
            await this.setUserPermissions();
            this.isAuthenticated = true;

            this.fetchSource();
            this.fetchPosition();
            this.fetchCity();
            this.fetchRound();
            this.fetchSalon();
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e.message);
        }
    },

    async destroyed() {
        localStorage.removeItem('userPermissions');
    },
};
</script>

<style lang="scss">
.App {
    background-color: #31343d;
}
</style>
