import authRepository from '../repository/authRepository';

export default {
    async refreshToken(userLogin) {
        let response = await authRepository.refreshToken({
            accessToken: userLogin.accessToken,
            refreshToken: userLogin.refreshToken,
            idToken: userLogin.idToken,
        });
        if (response.data && response.data.accessToken) {
            userLogin.accessToken = response.data.accessToken;
        }

        return userLogin;
    },
};
