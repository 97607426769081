import { HISTORY_SET_ID_CANDIDATE } from '../mutation-types';
// import { RepositoryFactory } from '../../../repository/RepositoryFactory';
// const cityApi = RepositoryFactory.get('city');

const initState = {
    idCandidate: null,
};

const mutations = {
    [HISTORY_SET_ID_CANDIDATE](state, idCandidate) {
        state.idCandidate = idCandidate;
    },
};

const actions = {};

export default {
    namespaced: true,
    mutations,
    actions,
    state: initState,
};
