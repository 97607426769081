import axiosRepository from './Repository';

const baseURL = '/api/v1/';

export default {
    addNewCandidate({ candidate }) {
        return axiosRepository.post(`${baseURL}candidates`, candidate);
    },

    editCandidate(candidate) {
        return axiosRepository.put(
            `${baseURL}candidates/${candidate.id}`,
            candidate
        );
    },

    fetch(params) {
        return axiosRepository.post(
            `${baseURL}candidates/q?page=${params.page}&size=${
                params.pageSize
            }&sort=id,DESC`,
            params
        );
    },

    fetchInSalon(params) {
        return axiosRepository.post(
            `${baseURL}candidates/salons?sort=id,DESC`,
            params
        );
    },

    fetchPointStylist(id) {
        return axiosRepository.get(`${baseURL}candidates/${id}/skill-test`);
    },

    updatePointStylist(params) {
        return axiosRepository.post(
            `${baseURL}candidates/${params.id}/skill-test`,
            params.point
        );
    },

    fetchById(id) {
        return axiosRepository.get(`${baseURL}candidates/${id}`);
    },

    fetchDataExport(params) {
        return axiosRepository.post(
            `${baseURL}candidates/q?sort=id,DESC`,
            params
        );
    },

    importCandidate(file) {
        let formData = new FormData();
        formData.append('file', file);
        return axiosRepository.post(`${baseURL}candidates/import`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },

    checkExist(idCard) {
        return axiosRepository.get(
            `${baseURL}candidates/q?id_number=${idCard}`
        );
    },

    updateStatusInterview(candidateId, params) {
        return axiosRepository.put(
            `${baseURL}candidates/${candidateId}/statuses`,
            params
        );
    },

    fetchHistory(id) {
        return axiosRepository.get(`${baseURL}candidates/${id}/histories`);
    },
};
