import axios from 'axios';
import config from '../configs/ApplicationConfig';

export const salonRepository = axios.create({
    baseURL: config.salonEndpoint,
});

const baseURL = '/api/v1/';

export default {
    fetch() {
        return salonRepository.get(`${baseURL}salons`);
    },
};
