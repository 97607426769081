import { ROUND_SET } from '../../mutation-types';
import { RepositoryFactory } from '../../../repository/RepositoryFactory';
const roundApi = RepositoryFactory.get('round');

const initState = {
    data: [],
};

const mutations = {
    [ROUND_SET](state, data) {
        state.data = data;
    },
};

const actions = {
    async fetch({ commit }) {
        const {
            data: { data: rounds },
        } = await roundApi.fetch();
        commit(ROUND_SET, rounds);
    },
};

const getters = {};

export default {
    namespaced: true,
    mutations,
    actions,
    state: initState,
    getters,
};
