import { POSITION_SET } from '../../mutation-types';
import { RepositoryFactory } from '../../../repository/RepositoryFactory';
const staffType = RepositoryFactory.get('staffType');

const initState = {
    data: [],
};

const mutations = {
    [POSITION_SET](state, data) {
        state.data = data;
    },
};

const actions = {
    async fetch({ commit }) {
        const {
            data: { data: positions },
        } = await staffType.fetch();
        commit(POSITION_SET, positions);
    },
};

const getters = {
    getPositions(state) {
        return state.data;
    }
};

export default {
    namespaced: true,
    mutations,
    actions,
    state: initState,
    getters,
};
